import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import styles from "../roomdetail.module.css";
import moment from "moment/moment";
import "moment-timezone";
import "moment/locale/id";

const CardScheduleBooked = ({ bookingData, index }) => {
  return (
    <Flex key={`item no.${index}`}>
      <Box className={styles["schedule-card"]}>
        <Flex>
          <Box width={"25%"}>
            <Flex className={styles["room-detail-info-card"]}>
              <Text className={styles["text-variable"]}>Time:</Text>
            </Flex>
          </Box>
          <Box width={"75%"}>
            <Flex className={styles["room-detail-info-card"]}>
              <Text className={styles["text-value"]}>
                {moment(bookingData?.start_usage).format("DD MMM YYYY, HH:mm")} -{" "}
                {moment(bookingData?.finish_usage).format("DD MMM YYYY, HH:mm")}
              </Text>
            </Flex>
          </Box>
        </Flex>
        <Flex>
          <Box width={"25%"}>
            <Flex className={styles["room-detail-info-card"]}>
              <Text className={styles["text-variable"]}>Meeting:</Text>
            </Flex>
          </Box>
          <Box width={"70%"}>
            <Flex className={styles["room-detail-info-card"]}>
              <Text className={styles["text-value"]}>
                {bookingData?.booking_room_booking_list?.booking_purpose}
              </Text>
            </Flex>
          </Box>
        </Flex>
        <Flex>
          <Box width={"25%"}>
            <Flex className={styles["room-detail-info-card"]}>
              <Text className={styles["text-variable"]}>PIC:</Text>
            </Flex>
          </Box>
          <Box width={"75%"}>
            <Flex className={styles["room-detail-info-card"]}>
              <Text className={styles["text-value"]}>
                {bookingData?.booking_room_booking_list?.user?.user_name}
              </Text>
            </Flex>
          </Box>
        </Flex>
        <Flex>
          <Box width={"25%"}>
            <Flex className={styles["room-detail-info-card"]}>
              <Text className={styles["text-variable"]}>Contact:</Text>
            </Flex>
          </Box>
          <Box width={"75%"}>
            <Flex className={styles["room-detail-info-card"]}>
              <Text className={styles["text-value"]}>
                {bookingData?.booking_room_booking_list?.user_contact}
              </Text>
            </Flex>
          </Box>
        </Flex>
        <Flex>
          <Box width={"25%"}>
            <Flex className={styles["room-detail-info-card"]}>
              <Text className={styles["text-variable"]}>Status:</Text>
            </Flex>
          </Box>
          <Box width={"75%"}>
            <Flex className={styles["room-detail-info-card"]}>
              <Text className={styles["text-value"]}>{bookingData?.status}</Text>
            </Flex>
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
};

export default CardScheduleBooked;
