import { Flex, Center, Image, useToast, Text, Skeleton } from "@chakra-ui/react";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Filter from "../../../components/BookingRoom/Filter";
import Header from "../../../components/Headers/Header";
import RoomItem from "../../../components/BookingRoom/RoomItem";
import Axios from "../../../utils/axiosService";
import EmptySchedule from "../../../assets/img/empty-room-schedule.svg";
import moment from "moment/moment";
import CustomToast from "../../../components/Toast/CustomToast";

function BookingRoomView() {
  moment.locale();
  let initialFilter = JSON.parse(localStorage.getItem("filterRoom"));
  const emptyFilter = {
    roomName: "",
    location: "",
    type: "",
    capacity: 0,
    date: new Date().toISOString().split("T")[0],
    start: "00:00",
    end: "01:00",
  };
  const [filter, setFilter] = useState(initialFilter || emptyFilter);

  const [isLoading, setIsLoading] = useState(true);
  const [errMessage, setErrMessage] = useState("");
  const [roomData, setRoomData] = useState([]);
  const toast = useToast();
  const [flagging, setFlagging] = useState(1);

  const getUserClassificationData = async (userId) => {
    try {
      const result = await Axios({
        method: "GET",
        url: `/user/${userId}`,
      });

      return { ...result.data.data.data };
    } catch (error) {
      throw error;
    }
  };

  const checkRoomByUserGroups = async (roomData) => {
    try {
      const userId = JSON.parse(localStorage.getItem("id_user"));
      const singleUserData = await getUserClassificationData(userId);
      const userGroupsData = singleUserData?.user_groups;

      const userGroupIds = new Set(userGroupsData.map((userGroup) => userGroup.id));

      const roomsWithoutUserGroups = roomData.filter((room) => {
        return (
          !room.user_groups_id.length ||
          room.user_groups_id.some((groupId) => userGroupIds.has(groupId))
        );
      });
      return roomsWithoutUserGroups;
    } catch (error) {
      return error;
    }
  };

  const checkRoomData = (queryOccurrence, resRoomData) => {
    return new Promise((resolve, reject) => {
      let checkResult = [];
      Axios({
        method: "GET",
        url: `/room/check/booking?active=true${queryOccurrence}`,
      })
        .then((resOccurrence) => {
          let tempResOccurrence = resOccurrence?.data?.data?.data;
          if (tempResOccurrence) {
            const resRoomDataFiltered = resRoomData.data.data.data;

            const filteredArray = resRoomDataFiltered.filter(
              (objA) => !tempResOccurrence.some((objB) => objA.id === objB.id)
            );

            checkResult = filteredArray;
          } else {
            checkResult = [];
          }
          if (checkResult.length === 0) {
            setErrMessage(
              "No rooms are available for booking. Please try a different date, time or location"
            );
          }
          resolve(checkResult);
        })
        .catch((err) => {
          if (err.response.status === 404 || err.response.data.errors[0].code === 202) {
            checkResult = resRoomData.data.data.data;
            resolve(checkResult);
          } else {
            reject(err);
            setErrMessage(
              "Unable to load Rooms. Please check back later or contact the administrator for assistance."
            );

            toast({
              position: "top",
              render: () => (
                <CustomToast
                  title="Error"
                  description={err.response.data.errors[0]}
                  status="error"
                  variant="outline"
                />
              ),
              duration: 3000,
              isClosable: true,
            });
          }
        });
    });
  };

  const handleRunFilter = (queryFilter) => {
    setIsLoading(true);

    let roomFilter = filter?.roomName;
    let locationFilter = filter?.location;

    if (locationFilter) {
      queryFilter += `&area_id=${locationFilter}`;
    }
    if (filter.type) {
      queryFilter += `&type=${filter.type}`;
    }
    if (filter.capacity) {
      queryFilter += `&capacity_gte=${filter.capacity}`;
    }
    if (roomFilter) {
      queryFilter += `&name=${roomFilter}`;
    }

    Axios({
      method: "GET",
      url: `/room?active=true${queryFilter}`,
    }).then(async (resRoomData) => {
      let queryOccurrence = "";

      if (filter.date) {
        if (filter.start && filter.end) {
          queryOccurrence += `&start_usage_eq=${filter.start}`;
          queryOccurrence += `&finish_usage_eq=${filter.end}`;
        }

        if (filter.location) {
          queryOccurrence += `&area_id=${filter.location}`;
        }

        queryOccurrence += `&date=${filter.date}`;
        queryOccurrence += `&status=Booking Created, Booking Confirmed`;

        localStorage.setItem("filterRoom", JSON.stringify(filter));

        let checkRoomDataResult = await checkRoomData(queryOccurrence, resRoomData);

        let filterRoomByUserGroups = await checkRoomByUserGroups(checkRoomDataResult);

        setRoomData(filterRoomByUserGroups);
        setIsLoading(false);
      } else {
        toast({
          position: "top",
          render: () => (
            <CustomToast
              title="Error"
              description="Please fill the Date Filter first!"
              status="error"
              variant="outline"
            />
          ),
          duration: 1500,
          isClosable: true,
        });
        setErrMessage("Please fill the Date Filter first");
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    if (initialFilter) {
      let queryFilter = "";
      handleRunFilter(queryFilter);
    } else {
      Axios({
        method: "GET",
        url: "/room?active=true",
      }).then((res) => {
        setIsLoading(false);
        setRoomData(res.data.data.data);
      });
    }
    //eslint-disable-next-line
  }, []);

  const handleSubmit = () => {
    let query = "";
    handleRunFilter(query);
  };

  const getAllRoomData = () => {
    setFilter(emptyFilter);
    setIsLoading(true);
    Axios({
      method: "GET",
      url: "/room?active=true&order_column=area_id&order_sequence=ASC",
    }).then(async (resRoomData) => {
      let queryOccurrence = "";

      if (emptyFilter.date) {
        if (emptyFilter.start && emptyFilter.end) {
          queryOccurrence += `&start_usage_eq=${emptyFilter.start}`;
          queryOccurrence += `&finish_usage_eq=${emptyFilter.end}`;
        }

        if (emptyFilter.location) {
          queryOccurrence += `&area_id=${emptyFilter.location}`;
        }

        queryOccurrence += `&date=${emptyFilter.date}`;
        queryOccurrence += `&status=Booking Created, Booking Confirmed`;

        let checkRoomDataResult = await checkRoomData(queryOccurrence, resRoomData);

        let filterRoomByUserGroups = await checkRoomByUserGroups(checkRoomDataResult);

        setRoomData(filterRoomByUserGroups);
        setIsLoading(false);
        setFlagging((prevValue) => (prevValue === 1 ? 2 : 1));
      }
    });
  };

  return (
    <>
      <Flex position="sticky" top="0" bg="#FFF" gap="18px" flexDir="column">
        <Header />
        <Filter
          key={`filter_${flagging}`}
          setIsLoading={setIsLoading}
          filter={filter}
          setFilter={setFilter}
          emptyFilter={emptyFilter}
          handleSubmit={handleSubmit}
          getAllRoomData={getAllRoomData}
        />
      </Flex>
      <Flex flexDirection="column" paddingX="38px" marginTop="10px" gap="18px" marginBottom="3em">
        {isLoading ? (
          <div style={{ zIndex: "5" }}>
            <Skeleton
              height="90px"
              padding="0.5em 1em"
              borderRadius="10px"
              bgColor="rgba(234, 249, 255, 0.5)"
            />
            <Skeleton
              height="90px"
              margin="1em 0"
              padding="0.5em 1em"
              borderRadius="10px"
              bgColor="rgba(234, 249, 255, 0.5)"
            />
            <Skeleton
              height="90px"
              margin="1em 0"
              padding="0.5em 1em"
              borderRadius="10px"
              bgColor="rgba(234, 249, 255, 0.5)"
            />
          </div>
        ) : roomData.length > 0 ? (
          roomData.map((room, idx) => <RoomItem room={room} key={idx} />)
        ) : (
          <>
            <Center mt="2em">
              <Image src={EmptySchedule} height={"250px"} />
            </Center>
            <Center>
              <Text style={{ whiteSpace: "pre-line", textAlign: "center" }}>
                {errMessage?.replace(".", ". \n") || "No Room Available"}
              </Text>
            </Center>
          </>
        )}
      </Flex>
    </>
  );
}

export default BookingRoomView;
